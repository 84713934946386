import React, {useEffect, useState, useContext, useMemo} from 'react';
import {Context} from '../AppContext';
import {DELIVERY_TYPE} from '../dictionary';

export default function useLogisticData({order, logistics}) {
  const [records, setRecords] = useState(logistics || []); // logistics maybe undefined
  const isCreated = records.length > 0;
  const cart = useMemo(() => {
    try {
      return JSON.parse(order.cart);
    } catch (error) {}
  }, [order]);

  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      if (order && !logistics) {
        try {
          let resp = await app.actions.getLogistics({
            order: order.id,
          });
          setRecords(resp.results);
        } catch (err) {
          console.warn(err);
        }
      }
    })();
  }, [order, app?.actions, logistics]);

  let result = {
    id: null,
    logistics_type: Object.keys(DELIVERY_TYPE)[0],
    logistics_status: '',
    tracking_number: '',
    is_delivery_private: false,
    sender_name: '',
    sender_phone: '',
    sender_tel_ext: '',
    sender_zip: '',
    sender_city: null,
    sender_district: null,
    sender_address: '',
    receiver_name: '',
    receiver_phone: '',
    receiver_tel: '',
    receiver_tel_ext: '',
    receiver_phone_spare: '',
    zip_code: '',
    receiver_city: null,
    receiver_district: null,
    receiver_address: '',
    logistics_note: '',
    shipping_time: null,
    updated: '',
    // ezship data:
    st_state: '', // 通路代號
    st_code: '', // 門市代號
    rstore_name: '', // 門市名稱
    rstore_tel: '', // 門市電話
    rstore_addr: '', // 門市地址
  };

  if (isCreated) {
    // 已產生出貨單
    let record = records[0];
    result = {
      ...record,
      sender_address: record.private_address || record.sender_address,
    };

    if (record.logistics_type === 'ezship') {
      result.st_state = cart.config.deliveryConfig.st_state;
      result.st_code = cart.config.deliveryConfig.st_code;
      result.rstore_name = cart.config.deliveryConfig.rstore_name;
      result.rstore_tel = cart.config.deliveryConfig.rstore_tel;
      result.rstore_addr = cart.config.deliveryConfig.rstore_addr;
    }
  } else {
    // 未產生出貨單，以order config代替

    if (cart && cart.config) {
      result = {
        ...result,
        ...cart.config.deliveryConfig,
        zip_code: cart.config.deliveryConfig?.zip,
        logistics_type: cart.config.deliveryConfig?.delivery_type,
        logistics_note: cart.config.deliveryConfig?.delivery_note,
      };
    }
  }

  return {
    logistic: result,
    isCreated,
  };
}
