import React, {Fragment} from 'react';
import {OrderBlock, Row, Text, Divider} from '../../Widgets';
import {EXRA_ORDER_TYPE} from '../../dictionary';
import {Link} from 'gatsby';

export default function ExtraOrderSection({order}) {
  if (!order) {
    return null;
  }
  return (
    <>
      <OrderBlock title="補收款訂單資訊">
        <Row>
          <Link to={`/order?id=${order.base_order}`}>
            <Text size="sm" style={{textDecoration: 'underline'}}>
              原訂單資訊
            </Text>
          </Link>
        </Row>
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            補收類別：
          </Text>
          <Text size="sm">{EXRA_ORDER_TYPE[order.extra_type]}</Text>
        </Row>

        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            補收原因：
          </Text>
          <Text size="sm">{order.description}</Text>
        </Row>

        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            補收金額：
          </Text>
          <Text size="sm">{order.amount}</Text>
        </Row>
      </OrderBlock>
    </>
  );
}
