import React from 'react';
import {OrderBlock, Row, Text} from '../../Widgets';
import {DEPOSIT_STATUS} from '../../dictionary';

function parseDetail(order) {
  let result = {};
  let detail = order.payment_transaction_detail || '{}';
  detail = detail.replace(/None/g, 'null').replace(/'/g, '"');

  try {
    result = JSON.parse(detail);
  } catch (err) {}
  return result;
}

export default function UserInfoSection({order, cart}) {
  if (!order) {
    return null;
  }

  let paymentData = parseDetail(order);

  return (
    <OrderBlock title="儲值交易內容">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          加值點數費用:
        </Text>
        <Text size="sm">{cart.calculations.amount}元</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          贈送點數:
        </Text>
        <Text size="sm">{cart.calculations.feedback_credits}點</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          點數面額:
        </Text>
        <Text size="sm">
          {cart.calculations.feedback_credits + cart.calculations.amount}點
        </Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          加值狀態:
        </Text>
        <Text size="sm">{DEPOSIT_STATUS[order.payment_status]}</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          建立時間:
        </Text>
        <Text size="sm">{order.created.slice(0, 19).replace('T', ' ')}</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          付款時間:
        </Text>
        <Text size="sm">
          {paymentData?.Result?.PayTime || '無法取得付款時間'}
        </Text>
      </Row>
    </OrderBlock>
  );
}
