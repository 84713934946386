import React, {useContext, useState, Fragment, useRef} from 'react';
import {Context} from '../AppContext';
import {message} from 'antd';
import {
  Input,
  Button,
  Divider,
  Text,
  Link,
  Row,
  Heading,
  TextArea,
  Select,
  SelectOption,
} from '../Widgets';
import {Check} from '@styled-icons/material';
const config = require('../data.json');

function Item({item, onClick, selected}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        margin: 8,
        borderBottom: '1px solid grey',
      }}>
      <div style={{width: 40}}>
        {selected && <Check size={24} color="black" />}
      </div>
      <div>
        <Text size="xs">姓名: {item.name || '---'}</Text>
        <Text size="xs">電話: {item.phone || '---'}</Text>
        <Text size="xs">信箱: {item.email || '---'}</Text>
      </div>
    </div>
  );
}

export default function ChangeBuyerModal(props) {
  const {order, onUpdate} = props;
  const [users, setUsers] = useState([]);
  const [buyer, setBuyer] = useState(null);

  const app = useContext(Context);

  const submit = async () => {
    app.actions.setLoading(true);

    if (!buyer) {
      return message.warning('請選擇使用者');
    }

    try {
      await app.actions.editOrderBuyer({
        id: order.id,
        buyer: buyer.id,
      });
      onUpdate();
      app.actions.setModal(null);
      setUsers([]);
      setBuyer(null);
      message.success('已成功變更訂單客戶');
    } catch (ex) {
      console.warn(ex);
      message.warning('欄位格式有誤！');
    }
    app.actions.setLoading(false);
  };

  const getUser = async (search) => {
    app.actions.setLoading(true);
    try {
      let resp = await app.actions.getUsers({
        search,
      });
      let {results, count} = resp;
      setUsers(results);
    } catch (err) {
      console.warn(err);
    }

    app.actions.setLoading(false);
  };

  return (
    <div>
      <Heading size="sm">顧客資訊</Heading>
      <Row>
        <Input.Search
          placeholder="請輸入姓名,電話,或信箱"
          onSearch={getUser}
          enterButton="搜尋顧客"
          allowClear={true}
        />
      </Row>
      {users.map((user) => (
        <Item
          item={user}
          selected={buyer && buyer.id === user.id}
          onClick={() => setBuyer(user)}
        />
      ))}

      <Divider />

      <Row style={{justifyContent: 'flex-end'}}>
        <Button
          onClick={() => {
            app.actions.setModal(null);
            setUsers([]);
            setBuyer(null);
          }}
          style={{marginRight: 10}}>
          取消
        </Button>
        <Button onClick={() => submit()}>更新訂單</Button>
      </Row>
    </div>
  );
}

const metadata = {
  title: <Heading align="center">變更訂單顧客</Heading>,
  width: '600px',
};

export {metadata};
