import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Button, TextArea, Hint, Text, Row} from '../Widgets';
import * as Ant from 'antd';
import {Close} from '@styled-icons/material/Close';
import {Context} from '../AppContext';
import {errorHandler} from '../errors';

export default function PeriodTerminateModal(props) {
  const {show, order, closeDialog, refresh} = props;
  const app = useContext(Context);
  const [period, setPeriod] = useState({});
  const [confirming, setConfirming] = useState();

  useEffect(() => {
    function getPeriod() {
      // app.actions.getPeriod(order.period_order.id)
      setPeriod(order.period_order);
    }

    if (order.order_type === 'period') {
      getPeriod();
    }
  }, [order]);

  const apply = useCallback(async () => {
    app.actions.setLoading(true);
    try {
      await app.actions.editPeriodNote(period);
      closeDialog();
      refresh();
    } catch (err) {
      errorHandler(err, '取消訂閱制錯誤');
    }
    app.actions.setLoading(false);
  }, [app.actions, period]);

  if (order.order_type !== 'period') {
    return null;
  }

  return (
    <Ant.Modal
      visible={show}
      title="取消訂閱制"
      onCancel={closeDialog}
      footer={null}
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }>
      {confirming ? (
        <Ant.Result
          status="warning"
          title="是否取消訂閱制"
          subTitle="將會送出取消訂閱制申請"
          extra={[
            <Button key="cancel" onClick={() => setConfirming(false)}>
              取消
            </Button>,
            <Button
              key="ok"
              onClick={() => {
                apply();
                setConfirming(false);
              }}>
              確定
            </Button>,
          ]}
        />
      ) : (
        <React.Fragment>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              欲取消訂閱制的原因
            </Text>
            <TextArea
              value={period.note}
              onChange={(e) => {
                const note = e.target.value;
                setPeriod((prev) => ({...prev, note}));
              }}
            />
          </Row>
          <Row>
            <Hint>
              此次的訂閱制訂單無法做退款處理，取消將會執行在下次扣款日的訂單
            </Hint>
          </Row>
          <Row>
            {period.status === 'activity' ? (
              <Hint>取消訂閱制會經由工作人員審核，才可終止</Hint>
            ) : period.status === 'review_waiting' ? (
              <Hint>此委託單已申請終止，等待工作人員審核中</Hint>
            ) : null}
          </Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}>
            <Button
              disabled={period.status !== 'activity'}
              onClick={() => {
                if (!period.note) {
                  return Ant.message.warning('請填寫取消原因。');
                }
                setConfirming(true);
              }}>
              確定
            </Button>
          </div>
        </React.Fragment>
      )}
    </Ant.Modal>
  );
}
