import React, {useContext, useMemo} from 'react';
import {Button, Text} from '../../Widgets';
import {message} from 'antd';
import {Context} from '../../AppContext';
import Tracking from '../../Tracking';
const appConfig = require('../../data.json');

export default function CheckoutButton({order}) {
  const app = useContext(Context);
  const {profile} = app.state;
  const cart = useMemo(() => {
    try {
      return JSON.parse(order.cart);
    } catch (error) {
      console.log(error);
      message.error('購物車資訊錯誤');
      return {};
    }
  }, [order]);

  const checkout = () => {
    if (
      order.payment_type === 'credits' &&
      profile.credits < cart.calculations.amount
    ) {
      return message.warning('您的點數餘額不足。');
    }

    app.actions.setLoading(true);

    const event_id =
      typeof crypto?.randomUUID === 'function'
        ? crypto.randomUUID()
        : new Date().toISOString();
    // Tracking.purchase(order, profile?.id, event_id);
    Tracking.checkout(order, cart, profile?.id, event_id);

    window.open(
      `${appConfig.endpoint.apiHost}/checkout/request/?order=${
        order.id
      }&token=${window.localStorage.getItem('token')}&event_id=${event_id}`,
      '_self',
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: 10,
      }}>
      {order.payment_status === 'code_generated' && (
        <Text size="sm" style={{marginRight: 10}}>
          已產生付款資訊，請見付款資訊的資料欄位
        </Text>
      )}

      {order.payment_status !== 'success' &&
        order.payment_type === 'credits' &&
        profile.credits < cart.calculations.amount && (
          <Text size="sm" style={{marginRight: 10}}>
            您的點數餘額不足。
          </Text>
        )}

      {order.payment_type !== 'monthly' &&
        order.payment_type !== 'offline' &&
        order.payment_status !== 'success' &&
        // order.payment_status !== 'failure' &&
        order.payment_status !== 'refunded' &&
        !order.voided && (
          <Button
            disabled={order.payment_status === 'code_generated'}
            onClick={checkout}>
            前往付款產生繳費資訊
          </Button>
        )}
    </div>
  );
}
