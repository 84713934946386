import React, {Fragment} from 'react';
import {OrderBlock, Row, Text, Button} from '../../Widgets';
import useInvoiceData from '../../hooks/use-invoice-data';
import {
  LOVECODE_OPTIONS,
  INVOICE_TYPES,
  TWO_COPIES_TYPES,
} from '../../dictionary';
const config = require('../../data.json');

const downloadUrl = 'https://inv.ezpay.com.tw/Invoice_index/search_platform';

export default function UserInfoSection({order}) {
  const {isCreated, invoice} = useInvoiceData({order});

  if (!order) {
    return null;
  }
  const type = INVOICE_TYPES[invoice.invoice_type];
  const subtype = TWO_COPIES_TYPES[invoice.invoice_subtype];
  if (!type) {
    return (
      <OrderBlock title="發票資訊">
        <Row>
          <Text>發票資料有誤</Text>
        </Row>
      </OrderBlock>
    );
  }

  return (
    <OrderBlock title="發票資訊">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          發票種類：
        </Text>
        <Text size="sm">{type.label}</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          開立狀態：
        </Text>
        <Text size="sm">{isCreated ? '發票已開立' : '發票尚未開立'}</Text>
      </Row>

      {isCreated && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            發票號碼：
          </Text>
          <Text size="sm" color={config.colors.main}>
            {invoice.invoice_number}
          </Text>
        </Row>
      )}

      {type.value === 'donate' ? (
        <div>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              愛心碼：
            </Text>
            <Text size="sm">{invoice.love_code}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              捐贈單位名稱：
            </Text>
            <Text size="sm">
              {LOVECODE_OPTIONS.find((x) => x.value === invoice.love_code)
                ?.label || '-'}
            </Text>
          </Row>
        </div>
      ) : type.value === 'two_copies' && subtype ? (
        <div>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              載具類別：
            </Text>
            <Text size="sm">{subtype.label}</Text>
          </Row>

          {invoice.invoice_subtype !== 'ezpay_vehicle' && (
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                {subtype.label}
                {'：'}
              </Text>
              <Text size="sm" color={config.colors.main}>
                {invoice.carrier_num}
              </Text>
            </Row>
          )}
        </div>
      ) : type.value === 'three_copies' ? (
        <div>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              公司統編：
            </Text>
            <Text size="sm">{invoice.gui_number}</Text>
          </Row>
          {!isCreated && (
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                公司抬頭：
              </Text>
              <Text size="sm">{invoice.company_title}</Text>
            </Row>
          )}
        </div>
      ) : null}

      {isCreated && (
        <Fragment>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              發票日期：
            </Text>
            <Text size="sm">{invoice.create_time}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              隨機碼：
            </Text>
            <Text size="sm">{invoice.random_num}</Text>
          </Row>
          <Button href={downloadUrl}>下載發票證明聯</Button>
        </Fragment>
      )}

      {order.payment_type === 'monthly' && (
        <Row>
          <Text size="sm">
            月結付款的訂單，統一在結算出帳日開立，月結對帳單中開立一份三聯式發票
          </Text>
        </Row>
      )}
    </OrderBlock>
  );
}
