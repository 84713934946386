import React, {useContext, useState, Fragment, useRef} from 'react';
import {Context} from '../AppContext';
import {message, Result} from 'antd';
import {
  Input,
  Button,
  Divider,
  Text,
  Link,
  Row,
  Heading,
  TextArea,
  Select,
  SelectOption,
} from '../Widgets';
import {VOIDED_REASON} from '../dictionary';

export default function VoidOrderModal(props) {
  const {order, onUpdate} = props;
  const [confirming, setConfirming] = useState(false);
  const [values, _setValues] = useState({
    void_reason: VOIDED_REASON[0],
    note: '',
  });

  const app = useContext(Context);

  const voided = async () => {
    app.actions.setLoading(true);
    let {void_reason, note} = values;

    if (void_reason === '其他原因') {
      void_reason = void_reason + ' > ' + note;
    }

    try {
      await app.actions.voidOrder({id: order.id, void_reason});
      app.actions.setModal(null);
      setConfirming(false);
      onUpdate();
    } catch (ex) {
      message('取消發生錯誤');
      console.error('void api error', ex);
    }
    app.actions.setLoading(false);
  };

  function setValues(obj) {
    _setValues((prev) => ({...prev, ...obj}));
  }

  return confirming ? (
    <Result
      status="warning"
      title="是否取消此訂單"
      subTitle="取消訂單後，無法復原"
      extra={[
        <Button key="cancel" onClick={() => setConfirming(false)}>
          取消
        </Button>,
        <Button
          key="ok"
          onClick={() => {
            voided();
          }}>
          確定
        </Button>,
      ]}
    />
  ) : (
    <div>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          原因
        </Text>

        <Select
          value={values.void_reason}
          onChange={(value) => setValues({void_reason: value})}
          style={{flexBasis: '250px'}}>
          {VOIDED_REASON.map((x, idx) => (
            <SelectOption key={idx} value={x} style={{width: 'auto'}}>
              {x}
            </SelectOption>
          ))}
        </Select>
      </Row>
      {values.void_reason === '其他原因' && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            其他原因
          </Text>
          <TextArea
            value={values.note}
            onChange={(e) => {
              setValues({note: e.target.value});
            }}
          />
        </Row>
      )}

      <Divider />

      <Row style={{justifyContent: 'flex-end'}}>
        <Button
          onClick={() => app.actions.setModal()}
          style={{marginRight: 10}}>
          取消
        </Button>
        <Button onClick={() => setConfirming(true)}>確定</Button>
      </Row>
    </div>
  );
}

const metadata = {
  title: <Heading align="center">取消訂單</Heading>,
  width: '600px',
};

export {metadata};
