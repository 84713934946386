import React from 'react';
import {OrderBlock, Row, Text, Divider} from '../../Widgets';
import useLogisticData from '../../hooks/use-logistic-data';
import {
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_SELF,
  DELIVERY_TYPE,
  LOGISTIC_TRACKING_URL,
  EZSHIP_STATE,
} from '../../dictionary';
const config = require('../../data.json');

export default function LogisticSection({order}) {
  const {logistic, isCreated} = useLogisticData({order});

  return (
    <OrderBlock title="運送方式與配送資訊">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          運送方式：
        </Text>
        <Text size="sm">{DELIVERY_TYPE[logistic.logistics_type]}</Text>
      </Row>
      {isCreated && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            系統物流編號
          </Text>
          <Text size="sm"># {logistic.id} </Text>
        </Row>
      )}
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          狀態：
        </Text>
        <Text size="sm" type="long">
          {isCreated
            ? logistic.logistics_type === 'self_pick'
              ? LOGISTIC_STATUS_SELF[logistic.logistics_status]
              : LOGISTIC_STATUS[logistic.logistics_status]
            : '尚未有物流單（物流單將在付款完成後建立）'}
        </Text>
      </Row>

      {isCreated && (
        <>
          {['hct', 'mailing', 'tcat', 'kerry_tj'].includes(
            logistic.logistics_type,
          ) && (
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                物流宅配單號：
              </Text>
              <Text size="sm" color={config.colors.main}>
                {logistic.tracking_number || '資料尚未產生'}
              </Text>
              {LOGISTIC_TRACKING_URL[logistic.logistics_type] && (
                <a
                  href={LOGISTIC_TRACKING_URL[logistic.logistics_type]}
                  style={{margin: '0 10px'}}
                  target="_blank"
                  rel="noreferrer">
                  查件連結
                </a>
              )}
            </Row>
          )}

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              預計{logistic.logistics_type === 'self_pick' ? '可自取' : '出貨'}
              時間：
            </Text>
            <Text size="sm">
              {(logistic.shipping_time &&
                ` ${logistic.shipping_time.slice(0, 10)}`) ||
                '資料尚未產生'}
            </Text>
          </Row>

          {logistic.receipt_time && (
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                指定{logistic.logistics_type === 'self_pick' ? '自取' : '收貨'}
                時間：
              </Text>
              <Text size="sm">
                {logistic.receipt_time.slice(0, 10)}{' '}
                {logistic.receipt_time.slice(11, 16)}
              </Text>
            </Row>
          )}

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              資料最後更新時間：
            </Text>
            <Text size="sm">
              {(logistic.updated &&
                ` ${logistic.updated.slice(0, 10)} ${logistic.updated.slice(
                  11,
                  16,
                )}`) ||
                '資料尚未產生'}
            </Text>
          </Row>
        </>
      )}
      {['hct', 'mailing', 'tcat', 'kerry_tj', 'special_car'].includes(
        logistic.logistics_type,
      ) && (
        <>
          {logistic.is_delivery_private && (
            <>
              <Divider />

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  保密代寄：
                </Text>
                <Text size="sm">是</Text>
              </Row>
              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人：
                </Text>
                <Text size="sm">{logistic.sender_name}</Text>
              </Row>

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人手機：
                </Text>
                <Text size="sm">{logistic.sender_phone}</Text>
              </Row>

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人電話：
                </Text>
                <Text size="sm" type="short">
                  {logistic.sender_tel}
                </Text>
                <Text size="sm">
                  {logistic.sender_tel_ext
                    ? `# ${logistic.sender_tel_ext}`
                    : ''}
                </Text>
              </Row>

              {/* <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人備用電話：
                </Text>
                <Text size="sm">{logistic.sender_phone_spare || '-'}</Text>
              </Row> */}

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人地址：
                </Text>
                <Text size="sm">
                  {logistic.sender_zip} {logistic.sender_city}{' '}
                  {logistic.sender_district} {logistic.sender_address}
                </Text>
              </Row>
            </>
          )}

          <Divider />

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人：
            </Text>
            <Text size="sm">{logistic.receiver_name}</Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人手機：
            </Text>
            <Text size="sm">{logistic.receiver_phone}</Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人電話：
            </Text>
            <Text size="sm">{logistic.receiver_tel}</Text>
            <Text size="sm">
              {logistic.receiver_tel_ext
                ? `# ${logistic.receiver_tel_ext}`
                : ''}
            </Text>
          </Row>

          {/* <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人備用電話：
            </Text>
            <Text size="sm">{logistic.receiver_phone_spare || '-'}</Text>
          </Row> */}

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人地址：
            </Text>
            <Text size="sm">
              {logistic.zip_code} {logistic.receiver_city}{' '}
              {logistic.receiver_district} {logistic.receiver_address}
            </Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件備註：
            </Text>
            <Text size="sm" color={config.colors.main}>
              {logistic.logistics_note}
            </Text>
          </Row>
        </>
      )}
      {logistic.logistics_type === 'ezship' && (
        <>
          <Divider />
          {isCreated && (
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                店到店單號：
              </Text>
              <Text size="sm">{logistic.tracking_number}</Text>
              <a
                href={LOGISTIC_TRACKING_URL[logistic.logistics_type]}
                style={{margin: '0 10px'}}
                target="_blank"
                rel="noreferrer">
                查件連結
              </a>
            </Row>
          )}

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              通路：
            </Text>
            <Text size="sm">{EZSHIP_STATE[logistic.st_state]}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              門市名稱：
            </Text>
            <Text size="sm">{logistic.rstore_name}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              門市地址：
            </Text>
            <Text size="sm">{logistic.rstore_addr}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人：
            </Text>
            <Text size="sm">{logistic.receiver_name}</Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人手機：
            </Text>
            <Text size="sm">{logistic.receiver_phone}</Text>
          </Row>
        </>
      )}
    </OrderBlock>
  );
}
