import React, {useMemo} from 'react';
import config from '../../data.json';
import {OrderBlock, Row, Text} from '../../Widgets';
import {PERIOD_STATUS} from '../../dictionary';

export default function PeriodSection(props) {
  const {order} = props;
  const period = order.period_order;

  const {Status, Message, Result} = useMemo(() => {
    try {
      let obj = JSON.parse(order?.payment_transaction_detail);
      return {...obj};
    } catch (err) {
      return {};
    }
  }, [order]);

  if (!period) {
    return null;
  }

  return (
    <OrderBlock title="委託單資料">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          委託單成立狀態：
        </Text>
        <Text
          size="sm"
          color={
            Status === 'SUCCESS' ? config.colors.main : config.colors.error
          }>
          {Status === 'SUCCESS' ? '成功' : '錯誤'}
        </Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          委託單狀態：
        </Text>
        <Text
          size="sm"
          color={
            period.status === 'activity'
              ? config.colors.main
              : config.colors.error
          }>
          {PERIOD_STATUS[period.status]}
        </Text>
      </Row>

      {period.status === 'extra_payment_waiting' && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            提醒：
          </Text>
          <Text size="sm" color={config.colors.error}>
            請前往訂單列表，補完優惠差額後，待工作人員確認完畢才能將訂閱單終止
          </Text>
        </Row>
      )}

      {period.status === 'rejected' && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            拒絕原因：
          </Text>
          <Text size="sm" color={config.colors.error}>
            {period.reject_reason}
          </Text>
        </Row>
      )}

      {Status !== 'SUCCESS' && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            錯誤訊息：
          </Text>
          <Text size="sm" color={config.colors.error}>
            {Message}
          </Text>
        </Row>
      )}

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          期數：
        </Text>
        <Text size="sm">
          {(Result.AlreadyTimes || 1) +
            '/' +
            (Result.TotalTimes || Result.AuthTimes)}
          {/* // first tranx is different */}
        </Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          授權金額：
        </Text>
        <Text size="sm">{Result.AuthAmt || Result.PeriodAmt}</Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          扣款日：
        </Text>
        <Text size="sm">
          {Result.AuthDate
            ? Result.AuthDate.slice(0, 10)
            : Result?.DateArray
            ? Result.DateArray.split(',')[0]
            : ''}
          {/* // first tranx is different */}
        </Text>
      </Row>
    </OrderBlock>
  );
}
