import React, {Fragment, useCallback, useContext, useState} from 'react';
import {OrderBlock, Row, Text, Divider, Button} from '../../Widgets';
import {dateUtil} from '../../Utils';
import {PAYMENT_STATUS} from '../../dictionary';
import PaymentOffline, {metadata} from '../../Modals/PaymentOffline';
import {Context} from '../../AppContext';
import {errorHandler} from '../../errors';
const config = require('../../data.json');

export default function PaymentSection({order, onUpdate}) {
  const app = useContext(Context);

  const editOrderRemit = useCallback(
    async (values) => {
      await app.actions.editOrderRemit({
        id: order?.id,
        ...values,
      });
      onUpdate();
    },
    [order],
  );

  if (!order) {
    return null;
  }

  const textColor = order.payment_status !== 'success' && config.colors.main;

  return (
    <OrderBlock title="付款資訊" color={config.colors.main}>
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          付款方式：
        </Text>
        <Text size="sm" color={textColor}>
          {' '}
          {
            {
              credits: '點數付款',
              neweb: '藍新付款',
              offline: '臨櫃匯款',
              monthly: '月結付款',
            }[order.payment_type]
          }{' '}
          {order.payment_type === 'neweb' &&
            {
              credit: '信用卡付款',
              cvs_cod: '超商取貨付款',
              cvs: '超商繳費',
              atm: 'ATM付款',
              web_atm: '網路ATM付款',
              barcode: '超商條碼繳費',
            }[order.payment_subtype]}
        </Text>
      </Row>

      {order.payment_status !== 'success' &&
        order.payment_status !== 'failure' &&
        order.code_no && (
          <Fragment>
            <Row>
              <Text size="sm" color={textColor} style={{marginRight: 10}}>
                {
                  {
                    atm: '轉帳帳號：',
                    cvs: '超商繳費代碼：',
                  }[order.payment_subtype]
                }
              </Text>
              {order.bank_code && (
                <Text size="sm" color={textColor}>
                  {' 銀行代碼 ' + order.bank_code + ' - 帳號 '}
                </Text>
              )}
              <Text size="sm" color={textColor}>
                {' '}
                {order.code_no}
              </Text>
            </Row>
            <Row>
              <Text size="sm" color={textColor} style={{marginRight: 10}}>
                繳費期限：
              </Text>
              <Text size="sm" color={textColor}>
                {dateUtil.format(order.pay_deadline, 'YYYY-MM-DD HH:mm')}
              </Text>
            </Row>
          </Fragment>
        )}
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          付款狀態：
        </Text>
        <Text size="sm" color={textColor}>
          {' '}
          {order.payment_type === 'monthly' &&
          order.payment_status === 'success'
            ? order.monthly_order
              ? '已出帳'
              : '未出帳'
            : PAYMENT_STATUS[order.payment_status]}
        </Text>
      </Row>
      {order.payment_type === 'offline' && (
        <Fragment>
          {config.information.offline_bank && (
            <Fragment>
              <Row>
                <Text size="sm" color={textColor} style={{marginRight: 10}}>
                  收款銀行：
                </Text>
                <Text size="sm" color={textColor}>
                  {config.information.offline_bank}
                </Text>
              </Row>
              <Row>
                <Text size="sm" color={textColor} style={{marginRight: 10}}>
                  收款帳號：
                </Text>
                <Text size="sm" color={textColor}>
                  {config.information.offline_account}
                </Text>
              </Row>
              <Row>
                <Text size="sm" color={textColor} style={{marginRight: 10}}>
                  收款戶名：
                </Text>
                <Text size="sm" color={textColor}>
                  {config.information.offline_account_name}
                </Text>
              </Row>
            </Fragment>
          )}

          <Row>
            <Button
              onClick={() =>
                app.actions.setModal({
                  content: (
                    <PaymentOffline
                      values={{
                        remittance_account: order.remittance_account,
                        remittance_number: order.remittance_number,
                        remitted_time: order.remitted_time,
                      }}
                      onClose={() => app.actions.setModal()}
                      onEdit={editOrderRemit}
                    />
                  ),
                  ...metadata,
                })
              }
              style={{marginRight: 10}}>
              填入匯款資訊
            </Button>
            {!config.information.offline_bank && (
              <Text size="sm" color={textColor}>
                請聯絡客服取得匯款資訊
              </Text>
            )}
          </Row>

          <Row>
            <Text size="sm" color={textColor} style={{marginRight: 10}}>
              匯款戶名：
            </Text>
            <Text size="sm" color={textColor}>
              {order.remittance_account}
            </Text>
          </Row>
          <Row>
            <Text size="sm" color={textColor} style={{marginRight: 10}}>
              匯款帳號後五碼：
            </Text>
            <Text size="sm" color={textColor}>
              {order.remittance_number}
            </Text>
          </Row>
          <Row>
            <Text size="sm" color={textColor} style={{marginRight: 10}}>
              匯款時間：
            </Text>
            <Text size="sm" color={textColor}>
              {dateUtil.format(order.remitted_time, 'YYYY-MM-DD HH:mm')}
            </Text>
          </Row>
        </Fragment>
      )}
      <Divider />
      <Row>
        <Text size="sm">
          {order.payment_type === 'offline'
            ? '匯出款項後，請務必以官方LINE訊息、臉書訊息或信箱告知以下資訊，以便會計進行款項確認。謝謝。'
            : '詳細繳費通知可查收E-mail信箱【藍新金流 NewebPay MPG付款交易通知書】，付款完成後藍新金流將發送收款通知信至您的E-mail信箱。'}
        </Text>
      </Row>
    </OrderBlock>
  );
}
