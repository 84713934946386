import {ORDER_STATE, ORDER_STATE_ICON, ORDER_TYPE} from '../dictionary';

export default function useOrderDisplayState({order = {}}) {
  let status = {
    title: '訂單成立',
    subtitle: '',
    step: 0,
    icon: '',
  };

  if (order) {
    let display_state = order.display_state;

    status.title = ORDER_STATE[display_state] || '-';
    status.subtitle = ORDER_STATE_ICON[display_state]?.subtitle || '-';
    status.icon = ORDER_STATE_ICON[display_state]?.icon || '';

    if (['extra', 'credit'].includes(order.order_type)) {
      status.subtitle = '此訂單為' + ORDER_TYPE[order.order_type];
    }

    if (
      order.display_state === 'logistic_transit' &&
      ['self_pick'].includes(order.logistics[0].logistics_type)
    ) {
      status.title = '可自取';
      status.subtitle = '訂單已理貨完成，可聯繫客服預約到店取貨！';
    }

    let phase = display_state.split('_')[0];
    status.step = {
      payment: 1,
      review: 2,
      production: 3,
      logistic: 4,
      completed: 5,
    }[phase];
  }

  return status;
}
